import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

const CRUDCategories = ({ allCategories, createCategory, updateCategory }) => {
  const [newCategory, setNewCategory] = useState(null)
  const [editCategory, setEditCategory] = useState(null)
  return (
    <div className="CRUDCategories">
      <div>All Work Categories</div>
      <div className="sortedList">
        {allCategories?.map((category) => (
          <div key={category.id} className="cat">
            <div>
              {editCategory?.id === category.id ? (
                <div>
                  <button
                    className="save"
                    onClick={() => {
                      updateCategory(editCategory)
                      setEditCategory(null)
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="cancel"
                    onClick={() => setEditCategory(null)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button onClick={() => setEditCategory(category)}>Edit</button>
              )}
            </div>
            <div className="name">
              {editCategory?.id === category.id ? (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editCategory.name}
                  onChange={(e) =>
                    setEditCategory({ ...editCategory, name: e.target.value })
                  }
                />
              ) : (
                category.name
              )}
            </div>
            <div className="description">
              {editCategory?.id === category.id ? (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editCategory.description}
                  onChange={(e) =>
                    setEditCategory({
                      ...editCategory,
                      description: e.target.value,
                    })
                  }
                />
              ) : (
                category.description
              )}
            </div>
            <div className="capacity">
              {editCategory?.id === category.id ? (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editCategory.capacity || "0"}
                  onChange={(e) =>
                    setEditCategory({
                      ...editCategory,
                      capacity: e.target.value,
                    })
                  }
                />
              ) : (
                category.capacity
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="new">
        {newCategory && (
          <>
            <div>New Category</div>
            <div>
              <div className="roww">
                <div className="label">Name</div>
                <div className="value">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newCategory.name}
                    onChange={(e) =>
                      setNewCategory({ ...newCategory, name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="roww">
                <div className="label">Description</div>
                <div className="value">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newCategory.description}
                    onChange={(e) =>
                      setNewCategory({
                        ...newCategory,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="roww">
                <div className="label">Capacity</div>
                <div className="value">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newCategory.capacity || "0"}
                    onChange={(e) =>
                      setNewCategory({
                        ...newCategory,
                        capacity: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="roww">
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (newCategory.name && newCategory.description) {
                      createCategory(newCategory)
                      setNewCategory({
                        name: "",
                        description: "",
                        capacity: "0",
                      })
                    }
                  }}
                >
                  <div>Add Category</div>
                </Button>
                <Button variant="outlined" onClick={() => setNewCategory(null)}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
        {!newCategory && (
          <Button
            variant="outlined"
            onClick={() =>
              setNewCategory({ name: "", description: "", capacity: "0" })
            }
          >
            Add New Category
          </Button>
        )}
      </div>
    </div>
  )
}

export default CRUDCategories
