import { useState, useEffect } from "react"
// import { PermissionsContext } from "../App"
import Page from "../components/Page"
// import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import "../styles/WorkPlannerPage.css"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import CRUDCategories from "../components/WorkCategoryEditor"
import WorkOverview from "../components/WorkOverview"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useNavigate } from "react-router-dom"

export const WorkPlannerPage = () => {
  // const [permissions] = useContext(PermissionsContext)
  const [editCategories, setEditCategories] = useState(false)
  const navigate = useNavigate()

  const { execute: executeCategories, data, isLoading } = useFetchWithMsal()
  const { execute: executePostCategories, isLoading: posting } =
    useFetchWithMsal()

  useEffect(() => {
    if (!data && !isLoading) {
      executeCategories("GET", "/api/work/plannedworks")
    }
  }, [executeCategories, data, isLoading])

  const createCategory = async (category) => {
    await executePostCategories("POST", "/api/work/categories", category)
    executeCategories("GET", "/api/work/plannedworks")
  }

  const updateCategory = async (category) => {
    await executePostCategories("POST", "/api/work/categories", category)
    executeCategories("GET", "/api/work/plannedworks")
  }

  return (
    <Page fullHeight className="WorkPlannerPage">
      {(isLoading || posting) && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div>Click on a Work Category to see details</div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setEditCategories(!editCategories)}
          >
            Categories
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/demand/B2B")}
            endIcon={<OpenInNewIcon />}
          >
            B2B
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/demand/cg/supplyplan")}
            endIcon={<OpenInNewIcon />}
          >
            CG
          </Button>
        </Stack>
      </div>
      <div className="body">
        <WorkOverview
          refresh={() => executeCategories("GET", "/api/work/plannedworks")}
          demandItems={data?.plannedWorks || []}
        />
      </div>
      <Dialog
        maxWidth="md"
        open={editCategories}
        onClose={() => setEditCategories(false)}
      >
        <Box style={{ padding: "10px" }}>
          <CRUDCategories
            allCategories={data?.allWorkCategories || []}
            createCategory={createCategory}
            updateCategory={updateCategory}
          />
        </Box>
      </Dialog>
    </Page>
  )
}
