import { useEffect, useState } from "react"
import Page from "../components/Page"
// import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import "../styles/CreateSalesOrder.css"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { IconButton, TextField } from "@mui/material"
import { getStates } from "../utils/salesUtils"
import { ProductPicker } from "../components/ProductPicker"
import DeleteIcon from "@mui/icons-material/Delete"

const defaultOrderItem = {
  productId: null,
  cases: 0,
  bottles: 0,
  price: 0,
}

const defaultOrder = {
  salesOrderTypeId: null,
  requiredDeliveryDate: null,
  rName: "",
  rEmail: "",
  rState: "",
  rStreet: "",
  rCity: "",
  rZip: "",
  rCountry: "United States",
  notes: "",
  items: [],
  additionalInfo: {
    eventOrRetail: "No",
    eventNonProfit: "",
    eventNonProfitName: "",
    licensedPremises: "",
    exemptStatus: "",
    taxStatusFile: null,
    //additionalFile: null,
  },
}

const states = getStates()

const YesOrNo = ({ value, onChange }) => {
  return (
    <select value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
      <option value=""></option>
    </select>
  )
}

const validateOrder = (order) => {
  if (!order.salesOrderTypeId) {
    return "Order Type is required"
  }
  if (!order.requiredDeliveryDate) {
    return "Delivery Date is required"
  }
  if (!order.rName) {
    return "Recipient Name is required"
  }
  if (!order.rEmail) {
    return "Recipient Email is required"
  }
  if (!order.rStreet) {
    return "Recipient Street is required"
  }
  if (!order.rCity) {
    return "Recipient City is required"
  }
  if (!order.rState) {
    return "Recipient State is required"
  }
  if (!order.rZip) {
    return "Recipient Zip is required"
  }
  if (!order.rCountry) {
    return "Recipient Country is required"
  }
  if (!order.items.length) {
    return "At least one product is required"
  }
  if (
    order.additionalInfo.exemptStatus === "Yes" &&
    order.additionalInfo.taxStatusFile === null
  ) {
    return "501c3 file upload required"
  }
  if (
    (order.additionalInfo?.eventNonProfit === "" ||
      (order.additionalInfo?.exemptStatus === "" &&
        order.additionalInfo?.eventNonProfit !== "No")) &&
    (order.salesOrderTypeId === "DN" || order.salesOrderTypeId === "EV")
  ) {
    return "Donation Compliance questions are required"
  }

  if (
    !order.additionalInfo.licensedPremises &&
    (order.salesOrderTypeId === "DN" ||
      order.salesOrderTypeId === "EV" ||
      order.salesOrderTypeId === "RT")
  ) {
    return "Retailer compliance questions are required"
  }
  for (let line of order.items) {
    if (!line.productId) {
      return "Product is required"
    }
    if (!line.cases && !line.bottles) {
      return "Cases or Bottles are required"
    }
  }
  return null
}

export const CreateSalesOrderPage = () => {
  const navigate = useNavigate()
  const [order, setOrder] = useState(defaultOrder)
  const [posting, setPosting] = useState(false)
  const [validation, setValidation] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [isCompliantOrder, setIsCompliantOrder] = useState(true)
  //const [validStatusMessage, setValidStatusMessage] = useState("")

  const {
    execute: executeGetReferenceData,
    data,
    isLoading,
  } = useFetchWithMsal()
  const { execute: executePost } = useFetchWithMsal()

  useEffect(() => {
    if (!data && !isLoading) {
      executeGetReferenceData("GET", "/api/sales/md")
    }
  }, [executeGetReferenceData, data, isLoading])

  const sendValidation = async () => {
    setPosting(true)
    const resp = await executePost("POST", "/api/sales/validate", order)
    setPosting(false)

    if (resp?.error) {
      alert(resp.error)
      return
    }

    setValidation(resp)
  }

  const sendCreate = async () => {
    setPosting(true)
    const resp = await executePost("POST", "/api/sales/create", order)
    setPosting(false)

    if (resp?.error) {
      alert(resp.error)
      return
    }

    navigate("/orders")
  }

  const ConvertArrayBufferToBase64 = (arrayBuffer) => {
    const uint8Array = new Uint8Array(arrayBuffer)
    const binaryString = uint8Array.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    )
    const base64String = btoa(binaryString)
    return base64String
  }

  useEffect(
    () => {
      if (!validation || Object.keys(validation).length === 0) return
      // setValidStatusMessage("")

      setIsCompliantOrder(true)

      if (!validation.compliance.isValid) {
        // setValidStatusMessage(
        //   (prevMessage) => `${prevMessage}\nNot a valid Configuration.`
        // )
        setIsCompliantOrder(false)
      }
      if (validation.order.additionalInfo.eventNonProfit === "No") {
        setIsCompliantOrder(false)
        // setValidStatusMessage(
        //   (prevMessage) =>
        //     `${prevMessage}\nProviding free goods may not be permitted in this instance.`
        // )
      }
      // Check if donating to nonprofit and state requires 501c3 status
      if (
        validation.order.additionalInfo.exemptStatus === "No" &&
        validation.compliance.requiresTaxExempt === true
      ) {
        setIsCompliantOrder(false)
        // setValidStatusMessage(
        //   (prevMessage) =>
        //     `${prevMessage}\n${order.rState} requires that donations to non-profits must have the 501c3 tax status.`
        // )
      }
      //check if can ship to retail licensed premises
      if (
        validation.order.additionalInfo.licensedPremises === "Yes" &&
        validation.compliance.retailShipping === false
      ) {
        setIsCompliantOrder(false)
        // setValidStatusMessage(
        //   (prevMessage) =>
        //     `${prevMessage}\n${order.rState} prohibits the shipping of product to retail licensed premises.`
        // )
      }
      if (
        validation.order.additionalInfo.licensedPremises === "No" &&
        validation.compliance.dtcShipping === false
      ) {
        setIsCompliantOrder(false)
      }
      setOrder({
        ...order,
        additionalInfo: {
          ...order.additionalInfo,
          isCompliantOrder: isCompliantOrder,
        },
      })
    }, // eslint-disable-next-line
    [order, validation]
  )

  return (
    <Page fullHeight className="CreateSalesOrderPage">
      {(isLoading || posting) && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div></div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/orders")}
            endIcon={<OpenInNewIcon />}
          >
            Your Orders
          </Button>
        </Stack>
      </div>
      <div className="body">
        <div className="formCont">
          <div className="title">Create a New Order</div>
          <div className="formSection">
            <div className="formRow">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "10px",
                }}
              >
                <label htmlFor="orderType" className="label">
                  Order Type
                </label>
                <select
                  name="orderType"
                  id="orderType"
                  onChange={(e) => {
                    const newOrderType = e.target.value

                    setOrder({
                      ...defaultOrder,
                      salesOrderTypeId: newOrderType,
                      rState: order?.rState,
                    })
                  }}
                >
                  <option value="">Select Order Type</option>
                  {data?.salesOrderTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextField
                  fullWidth
                  label="Delivery Date"
                  type="date"
                  value={order.requiredDeliveryDate || ""}
                  onChange={(e) =>
                    setOrder({ ...order, requiredDeliveryDate: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </div>
            </div>
            <div className="formRow">
              <TextField
                value={order.rName || ""}
                label="Recipient Name"
                onChange={(e) => setOrder({ ...order, rName: e.target.value })}
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.rEmail || ""}
                label="Recipient Email"
                onChange={(e) => setOrder({ ...order, rEmail: e.target.value })}
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.rStreet || ""}
                label="Recipient Street"
                onChange={(e) =>
                  setOrder({ ...order, rStreet: e.target.value })
                }
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.rCity || ""}
                label="Recipient City"
                onChange={(e) => setOrder({ ...order, rCity: e.target.value })}
                fullWidth
                required
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "10px",
                }}
              >
                <label htmlFor="rState" className="label">
                  State
                </label>
                <select
                  name="rState"
                  id="rState"
                  onChange={(e) =>
                    setOrder({ ...order, rState: e.target.value })
                  }
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="formRow">
              <TextField
                value={order.rZip || ""}
                label="Recipient Zip"
                onChange={(e) => setOrder({ ...order, rZip: e.target.value })}
                fullWidth
                required
              />
              <TextField
                value={order.rCountry || ""}
                label="Recipient Country"
                onChange={(e) =>
                  setOrder({ ...order, rCountry: e.target.value })
                }
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.notes || ""}
                label="Any additional information about event or recipient."
                onChange={(e) => setOrder({ ...order, notes: e.target.value })}
                fullWidth
                rows={2}
                multiline
              />
            </div>
            {/* <div>File Upload</div>
            <input
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0]
                if (!file) return

                const arrayBuffer = await file.arrayBuffer()
                const base64String = ConvertArrayBufferToBase64(arrayBuffer)
                setOrder({
                  ...order,
                  additionalInfo: {
                    ...order.additionalInfo,
                    additionalFile: {
                      name: file.name,
                      arrayBuffer: base64String,
                    },
                  },
                })
              }}
            />{" "} */}
          </div>
          <div className="itemsCont">
            <div className="header">Products (Required)</div>
            <div>
              {order.items?.map((line, i) => {
                const error = !line.productId
                  ? "Product is required"
                  : !line.cases && !line.bottles
                  ? "Cases or Bottles are required"
                  : null

                return (
                  <div key={i} className={`itemRow ${error ? "error" : ""}`}>
                    {error && <div className="errorBox">{error}</div>}
                    <div className="itemRowContent">
                      <div className="fields">
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <ProductPicker
                            products={data?.products}
                            id={line.productId}
                            onChange={(productId) =>
                              setOrder({
                                ...order,
                                items: order.items.map((item, index) =>
                                  index === i ? { ...item, productId } : item
                                ),
                              })
                            }
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            label="Cases"
                            type="number"
                            style={{ width: "100px" }}
                            value={line.cases}
                            required
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                items: order.items.map((item, index) =>
                                  index === i
                                    ? {
                                        ...item,
                                        cases: parseInt(e.target.value),
                                      }
                                    : item
                                ),
                              })
                            }}
                            inputProps={{ min: 0 }}
                          />
                          <TextField
                            label="Bottles"
                            type="number"
                            size="small"
                            style={{ width: "100px" }}
                            value={line.bottles}
                            required
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                items: order.items.map((item, index) =>
                                  index === i
                                    ? {
                                        ...item,
                                        bottles: parseInt(e.target.value),
                                      }
                                    : item
                                ),
                              })
                            }}
                            inputProps={{ min: 0 }}
                          />
                        </div>
                      </div>
                      <div>
                        <IconButton
                          onClick={() =>
                            setOrder({
                              ...order,
                              items: [
                                ...order.items.slice(0, i),
                                ...order.items.slice(i + 1),
                              ],
                            })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() =>
                  setOrder({
                    ...order,
                    items: [...order.items, { ...defaultOrderItem }],
                  })
                }
              >
                Add Line
              </Button>
            </div>
          </div>
          {(order.salesOrderTypeId === "DN" ||
            order.salesOrderTypeId === "EV") && (
            <div className="formSection">
              <div className="header">Compliance</div>

              <div className="formRow">
                <div>* Is the host of the event a non-profit organization?</div>
                <YesOrNo
                  value={order.additionalInfo.eventNonProfit}
                  onChange={(value) =>
                    setOrder({
                      ...order,
                      additionalInfo: {
                        ...order.additionalInfo,
                        eventNonProfit: value,
                        exemptStatus: "", // Reset 501c3 status when changing non-profit
                        eventNonProfitName: "", // Reset nonprofit name
                        taxStatusFile: null, // Reset uploaded file
                      },
                    })
                  }
                />
              </div>

              {order.additionalInfo.eventNonProfit === "Yes" && (
                <>
                  <div className="formRow">
                    <div>* Does this non-profit have 501c3 status?</div>
                    <YesOrNo
                      value={order.additionalInfo.exemptStatus}
                      onChange={(value) =>
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            exemptStatus: value,
                            taxStatusFile: null, // Reset uploaded file when status changes
                          },
                        })
                      }
                    />
                    <TextField
                      size="small"
                      value={order.additionalInfo.eventNonProfitName}
                      label="Nonprofit Name"
                      style={{ maxWidth: 150 }}
                      onChange={(e) =>
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            eventNonProfitName: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </>
              )}

              {order.additionalInfo.exemptStatus === "Yes" && (
                <div className="FileUpload">
                  <div className="formRow">
                    <div>* 501c3 Status Upload</div>
                    <input
                      type="file"
                      onChange={async (e) => {
                        const file = e.target.files[0]
                        if (!file) return

                        const arrayBuffer = await file.arrayBuffer()
                        const base64String =
                          ConvertArrayBufferToBase64(arrayBuffer)
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            taxStatusFile: {
                              name: file.name,
                              arrayBuffer: base64String,
                            },
                          },
                        })
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {(order.salesOrderTypeId === "DN" ||
            order.salesOrderTypeId === "EV" ||
            order.salesOrderTypeId === "RT") && (
            <div className="formSection">
              <div className="formRow">
                <div>
                  * Is this product being shipped to a retail licensed premises?
                </div>
                <YesOrNo
                  value={order.additionalInfo.licensedPremises}
                  onChange={(value) =>
                    setOrder({
                      ...order,
                      additionalInfo: {
                        ...order.additionalInfo,
                        licensedPremises: value,
                        eventOrRetail: "No", // Reset eventOrRetail when changing premises
                      },
                    })
                  }
                />
              </div>

              {order.additionalInfo.licensedPremises === "Yes" &&
                order.salesOrderTypeId !== "RT" && (
                  <div className="formRow">
                    <div>
                      Does the host of the event hold a special event permit or
                      retail license?
                    </div>
                    <select
                      value={order.additionalInfo.eventOrRetail}
                      onChange={(e) =>
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            eventOrRetail: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="Event">Special Event Permit</option>
                      <option value="Retail">Retail License</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const error = validateOrder(order)
                if (error) {
                  alert(error)
                  return
                }

                sendValidation()
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      {validation && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={!!validation}
          onClose={() => setValidation(null)}
        >
          <Box
            sx={{
              backgroundColor:
                isCompliantOrder && validation.compliance.isValid
                  ? "lightgreen"
                  : !validation.compliance.isValid
                  ? "#ff7a7a"
                  : "yellow",
              padding: "10px",
            }}
          >
            <div className="ComplianceNotes">
              <div className="header">Compliance Guidance</div>
              <div className="section">
                <div>
                  Type:{" "}
                  {
                    data.salesOrderTypes.find(
                      (type) => type.id === order.salesOrderTypeId
                    )?.description
                  }
                  <div>
                    State:{" "}
                    {
                      states.find((state) => state.value === order.rState)
                        ?.label
                    }
                  </div>
                </div>
              </div>
              {validation?.orderType?.complianceNotes && (
                <div className="section">
                  <div>{validation?.orderType?.complianceNotes}</div>
                </div>
              )}
              {!validation?.compliance && (
                <div className="section">
                  <div>No compliance guidance for this state/order type</div>
                </div>
              )}
              {/* {validation?.compliance?.allowed && (
                <div className="section">
                  <div className="h">
                    Allowed: {isCompliantOrder ? "Yes" : `No`}
                  </div>
                  {!isCompliantOrder && (
                    <div>
                      <b>Reason(s)</b>
                      {validStatusMessage}
                    </div>
                  )}
                </div>
              )} */}
              <div className="section">
                <div className="h">Notes</div>
                {validation?.compliance?.notes && (
                  <div>{validation?.compliance?.notes}</div>
                )}
                {validation?.compliance?.allowed && (
                  <div>{validation?.compliance?.allowed}</div>
                )}
              </div>
              {validation?.compliance?.quantityRestrictions && (
                <div className="section">
                  <div className="h">Quantity Restrictions</div>
                  <div>{validation?.compliance?.quantityRestrictions}</div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                marginTop: "20px",
              }}
            >
              {validation.compliance.isValid ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    isCompliantOrder ? sendCreate() : setConfirmation(true)
                  }
                >
                  Create Order
                </Button>
              ) : null}
              <Button variant="contained" onClick={() => setValidation(null)}>
                Cancel
              </Button>
            </div>
          </Box>
        </Dialog>
      )}
      {confirmation && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={!!confirmation}
          onClose={() => setConfirmation(null)}
        >
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <div className="ComplianceNotes">
              <div
                className="header"
                style={{
                  textAlign: "center",
                }}
              >
                Compliance Confirmation
              </div>
              <div className="section">
                {" "}
                <p>
                  This order does not meet the compliance requirements, Be
                  advised this order will be sent to compliance for further
                  review.
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "50px",
                  marginTop: "20px",
                }}
              >
                {validation.compliance.isValid ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => sendCreate()}
                  >
                    Submit
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  onClick={() => setConfirmation(null)}
                >
                  Close
                </Button>
              </div>
            </div>
          </Box>
        </Dialog>
      )}
    </Page>
  )
}
