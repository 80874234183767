import { useEffect, useState } from "react"
import Page from "../components/Page"
import ComplianceEditor from "../components/ComplianceEditor"
import LinearProgress from "@mui/material/LinearProgress"
import { Dialog, MenuItem, TextField } from "@mui/material"
import Stack from "@mui/material/Stack"
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { getStates } from "../utils/salesUtils"

const data_URL = "/api/sales/compliance"
const types_URL = "/api/sales/md?resources=salesOrderTypes"

const states = getStates()

const ComplianceAdminPage = () => {
  const [focusedRow, setFocusedRow] = useState(null)
  const [selectedState, setSelectedState] = useState("")
  const [selectedOrderType, setSelectedOrderType] = useState("")

  const {
    execute: executeGetComplianceInfo,
    data = [],
    isLoading,
  } = useFetchWithMsal()

  useEffect(() => {
    if (!data) {
      executeGetComplianceInfo("GET", data_URL)
    }
  }, [executeGetComplianceInfo, data])

  const { execute: executeGetSalesOrdersTypes, data: types = [] } =
    useFetchWithMsal()

  useEffect(() => {
    if (!types) {
      executeGetSalesOrdersTypes("GET", types_URL)
    }
  }, [executeGetSalesOrdersTypes, types])

  const handleDialogueClose = () => {
    setFocusedRow(null)
    executeGetComplianceInfo("GET", data_URL)
  }

  const filteredRows = data
    ?.map((e) => ({
      ...e,
      salesOrderTypeId:
        types?.salesOrderTypes.find((type) => type.id === e.salesOrderTypeId)
          ?.description || "Unknown Type",
    }))
    .filter((row) => (selectedState ? row.state === selectedState : true))
    .filter((row) =>
      selectedOrderType ? row.salesOrderTypeId === selectedOrderType : true
    )

  return (
    <Page fullHeight className="ComplianceAdminPage">
      {isLoading && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div>
            All Compliance Info - Click on a row to see the details and change
            status
          </div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <div></div>
        </Stack>
      </div>
      <div className="body" style={{ height: "calc(100% - 64px)" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            flex: 1,
            maxHeight: "100%",
          }}
          className="gridContainer"
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "state", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  isValid: false,
                  retailShipping: false,
                  requiresTaxExempt: false,
                  dtcShipping: false,
                },
              },
            }}
            rows={filteredRows || []}
            columns={[
              {
                field: "id",
                headerName: "ID",
                width: 80,
              },
              {
                field: "state",
                headerName: "State",
                width: 80,
              },
              {
                field: "salesOrderTypeId",
                headerName: "Order Type",
                width: 225,
              },
              {
                field: "allowed",
                headerName: "Allowed",
                width: 250,
              },
              {
                field: "notes",
                headerName: "Notes",
                width: 250,
              },
              {
                field: "isValid",
                headerName: "Enabled",
                width: 80,
              },
              {
                field: "retailShipping",
                headerName: "Retail Shipping",
                width: 80,
              },
              {
                field: "dtcShipping",
                headerName: "DTC Shipping",
                width: 80,
              },
              {
                field: "requiresTaxExempt",
                headerName: "Require 501c3",
                width: 80,
              },
            ]}
            hideFooterSelectedRowCount={true}
            disableDensitySelector
            slotProps={{
              toolbar: {},
            }}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  selectedOrderType={selectedOrderType}
                  setSelectedOrderType={setSelectedOrderType}
                  types={types}
                />
              ),
            }}
            pagination
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 40}
            columnHeaderHeight={40}
            pageSizeOptions={[100]}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
            onRowClick={(row) => setFocusedRow(row?.row)}
          />
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={!!focusedRow}
        onClose={() => setFocusedRow(null)}
      >
        <ComplianceEditor
          _focusedRow={focusedRow}
          onUpdate={handleDialogueClose}
        />
      </Dialog>
    </Page>
  )
}

const CustomToolbar = ({
  selectedState,
  setSelectedState,
  selectedOrderType,
  setSelectedOrderType,
  types,
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" padding={2}>
      <GridToolbar />
      <TextField
        select
        label="Filter by State"
        value={selectedState}
        onChange={(e) => setSelectedState(e.target.value)}
        variant="outlined"
        size="small"
        sx={{ minWidth: 150 }}
      >
        <MenuItem value="">All</MenuItem>
        {states.map((state) => (
          <MenuItem key={state.value} value={state.value}>
            {state.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Filter by Order Type"
        value={selectedOrderType}
        onChange={(e) => setSelectedOrderType(e.target.value)}
        variant="outlined"
        size="small"
        sx={{ minWidth: 200 }}
      >
        <MenuItem value="">All</MenuItem>
        {types?.salesOrderTypes?.map((type) => (
          <MenuItem key={type.id} value={type.description}>
            {type.description}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  )
}

export default ComplianceAdminPage
