import React from "react"
import { Box } from "@mui/material"

const TintedBackground = ({
  imageUrl,
  tintColor = "black",
  tintOpacity = 0.5,
  children,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: tintColor,
          opacity: tintOpacity,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          color: "white",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default TintedBackground
