import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import WorkDetail from "./WorkDetail"
import { useState } from "react"
import Gantt1 from "./B2BGantt"
import { apiListToGantFormat } from "./WorkDetail"
import "../styles/WorkOverview.css"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { format, parseISO, addMonths, startOfMonth } from "date-fns"
const aggregateWorkByMonth = (work) => {
  // Create array of all months for next 12 months
  const allMonths = Array.from({ length: 12 }, (_, i) => {
    const date = addMonths(startOfMonth(new Date()), i)
    return format(date, "yyyy-MM")
  })

  // Initialize monthlyData with all months set to 0
  const monthlyData = Object.fromEntries(allMonths.map((month) => [month, 0]))

  // Add quantities from work, distributed across months
  work.forEach(({ startDate, endDate, quantity }) => {
    const start = parseISO(startDate)
    const end = parseISO(endDate)
    const totalDays = (end - start) / (1000 * 60 * 60 * 24)

    // For each month in the range
    let currentDate = startOfMonth(start)
    while (currentDate <= end) {
      const monthKey = format(currentDate, "yyyy-MM")
      if (monthKey in monthlyData) {
        // Calculate days in this month that overlap with work period
        const monthStart = Math.max(start.getTime(), currentDate.getTime())
        const monthEnd = Math.min(
          end.getTime(),
          addMonths(currentDate, 1).getTime() - 1
        )
        const daysInMonth = (monthEnd - monthStart) / (1000 * 60 * 60 * 24)

        // Add proportional quantity for this month
        monthlyData[monthKey] += (quantity * daysInMonth) / totalDays
      }
      currentDate = addMonths(currentDate, 1)
    }
  })

  // Convert to array format
  return Object.entries(monthlyData).map(([month, quantity]) => ({
    month,
    quantity: Math.round(quantity * 100) / 100, // Round to 2 decimal places
  }))
}

const CategoryVisual = ({ category, work }) => {
  const data = aggregateWorkByMonth(work)

  return (
    <div className="category-visual">
      <ResponsiveContainer width="100%" height={100}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            className="axis"
            style={{ fontSize: "12px" }}
          />
          <YAxis className="axis" />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="quantity"
            stroke="#4A90E2"
            name="Quantity"
          />
          <Line
            type="monotone"
            dataKey={() => category.capacity || 0}
            name="Capacity"
            stroke="#FF0000"
            strokeDasharray="5 5"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

const WorkOverview = ({ demandItems, refresh }) => {
  const [expandedCategories, setExpandedCategories] = useState({})
  const [detailedDemandItem, setDetailedDemandItem] = useState(null)

  const groupedByCategory = {}

  demandItems.forEach((demandItem) => {
    demandItem.plannedWorks.forEach((work) => {
      const categoryId = work.workCategory.id
      if (!groupedByCategory[categoryId]) {
        groupedByCategory[categoryId] = {
          category: work.workCategory,
          demandWorks: [],
        }
      }
      groupedByCategory[categoryId].demandWorks.push({
        ...work,
        demandItem,
      })
    })
  })

  const handleToggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }))
  }

  return (
    <div className="WorkOverview">
      {Object.values(groupedByCategory).map(({ category, demandWorks }) => {
        const groupedByDemandItem = {}

        demandWorks.forEach((dw) => {
          const diId = dw.demandItem.id
          if (!groupedByDemandItem[diId]) {
            groupedByDemandItem[diId] = {
              demandItem: dw.demandItem,
              plannedWorks: [],
            }
          }
          groupedByDemandItem[diId].plannedWorks.push(dw)
        })

        return (
          <div key={category.id} className="WorkOverviewCategory">
            <div
              className="WorkOverviewCategoryHeader"
              onClick={() => handleToggleCategory(category.id)}
            >
              <div style={{ margin: 0, flex: 1 }} className="name">
                {category.name}
              </div>
              <div className="visual">
                <CategoryVisual category={category} work={demandWorks} />
              </div>
            </div>

            {expandedCategories[category.id] && (
              <div>
                <Gantt1
                  title={category.name}
                  tasks={apiListToGantFormat(
                    demandWorks,
                    (task) =>
                      `${task.demandItem.type} ${
                        task.demandItem.type === "B2B"
                          ? (task.demandItem.externalSalesContract
                              ?.customerName || task.demandItem.customer) +
                            " - " +
                            (task.demandItem.externalSalesContract?.blend ||
                              task.demandItem.contractBlend)
                          : task.demandItem.allocationBlend
                      }`,
                    true
                  )}
                  handleTaskDelete={() => {}}
                  handleTaskChange={() => {}}
                  handleTaskReorder={() => {}}
                  handleUpdateQuantity={() => {}}
                  readOnly={true}
                  headerColWidth={"400px"}
                  onRowClick={(task) => {
                    setDetailedDemandItem(task.demandItem)
                  }}
                  ganttHeight={0}
                />
              </div>
            )}
          </div>
        )
      })}
      {detailedDemandItem && (
        <Dialog
          fullWidth
          open={!!detailedDemandItem}
          onClose={() => {
            refresh()
            setDetailedDemandItem(null)
          }}
          maxWidth="xl"
        >
          <Box style={{ padding: "10px" }}>
            <WorkDetail
              demand={detailedDemandItem}
              close={() => {
                refresh()
                setDetailedDemandItem(null)
              }}
            />
          </Box>
        </Dialog>
      )}
    </div>
  )
}

export default WorkOverview
