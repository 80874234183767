import { Gantt } from "gantt-task-react"
import "gantt-task-react/dist/index.css"
import React, { useState } from "react"

// function getStartEndDateForProject(tasks, projectId) {
//   const projectTasks = tasks.filter((t) => t.project === projectId)
//   let start = projectTasks[0].start
//   let end = projectTasks[0].end

//   for (let i = 0; i < projectTasks.length; i++) {
//     const task = projectTasks[i]
//     if (start.getTime() > task.start.getTime()) {
//       start = task.start
//     }
//     if (end.getTime() < task.end.getTime()) {
//       end = task.end
//     }
//   }
//   return [start, end]
// }

// need to allow for quantity to be updated
// on click, the quantity should be editable and show a save button and a cancel button
const Quantity = ({ task, handleUpdateQuantity, readOnly = false }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [quantity, setQuantity] = useState(task.quantity)

  const handleSave = () => {
    handleUpdateQuantity(quantity)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setQuantity(task.quantity)
    setIsEditing(false)
  }

  return (
    <div>
      {isEditing ? (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <input
            style={{
              width: "70px",
              fontSize: "10px",
              padding: "2px",
              border: "none",
            }}
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            disabled={readOnly}
          />
          <button onClick={handleSave}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      ) : (
        <div style={{ display: "flex", gap: "5px" }}>
          {`${Number(quantity).toLocaleString()} gals`}
          <button onClick={() => setIsEditing(true)} disabled={readOnly}>
            ✏️
          </button>
        </div>
      )}
    </div>
  )
}

const Gantt1 = ({
  title = "Work Planner",
  tasks,
  handleTaskDelete,
  handleTaskChange,
  handleTaskReorder,
  handleUpdateQuantity,
  readOnly = false,
  headerColWidth = "200px",
  onRowClick = () => {},
  ganttHeight = 340,
}) => {
  const [isChecked] = useState(true)

  const view = "Month"
  let columnWidth = 65
  if (view === "Year") {
    columnWidth = 350
  } else if (view === "Month") {
    columnWidth = 160
  } else if (view === "Week") {
    columnWidth = 250
  }

  const sortedTasks = [
    ...tasks.map((t) => ({ ...t, displayOrder: t.displayOrder + 100 })),
  ].sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))

  return (
    <Gantt
      tasks={sortedTasks}
      viewMode={view}
      onDateChange={(task) => handleTaskChange(sortedTasks, task)}
      listCellWidth={isChecked ? "155px" : ""}
      rowHeight={40}
      headerHeight={40}
      ganttHeight={ganttHeight}
      columnWidth={columnWidth}
      TooltipContent={({ task }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            padding: "5px",
            backgroundColor: "white",
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
          }}
        >
          <div>{task.name}</div>
          <div>{`Start: ${task.start.toLocaleDateString()}`}</div>
          <div>{`End: ${task.end.toLocaleDateString()}`}</div>
          <div>{`Quantity: ${
            task.quantity ? Number(task.quantity).toLocaleString() : ""
          } gals`}</div>
        </div>
      )}
      TaskListHeader={({ headerHeight }) => (
        <div
          style={{
            width: headerColWidth,
            height: headerHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid #e0e0e0",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          {title}
        </div>
      )}
      TaskListTable={({
        rowHeight,
        tasks,
        // selectedTaskId,
        // setSelectedTask,
      }) => {
        return (
          <div className="WORKGANTTABLE">
            {tasks.map((task) => {
              return (
                <div
                  className="WORKGANTROW"
                  key={task.id}
                  style={{
                    width: headerColWidth,
                    height: rowHeight,
                    borderTop: "1px solid grey",
                    borderBottom: "1px solid grey",
                    padding: "2px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => onRowClick(task)}
                  >
                    {!readOnly && (
                      <button
                        onClick={() => handleTaskDelete(task.id)}
                        disabled={readOnly}
                      >
                        ❌
                      </button>
                    )}
                    <div className="label" style={{ flex: "1" }}>
                      {task.name}
                    </div>
                    {!readOnly && (
                      <>
                        <button
                          disabled={readOnly}
                          onClick={() => handleTaskReorder(tasks, task, "up")}
                        >
                          ⬆️
                        </button>
                        <button
                          disabled={readOnly}
                          onClick={() => handleTaskReorder(tasks, task, "down")}
                        >
                          ⬇️
                        </button>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "flex-end",
                      height: "20px",
                      fontSize: "12px",
                    }}
                  >
                    <Quantity
                      readOnly={readOnly}
                      task={task}
                      handleUpdateQuantity={(quantity) =>
                        handleUpdateQuantity(tasks, task, quantity)
                      }
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default Gantt1
