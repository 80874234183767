import Gantt1 from "./B2BGantt"
import { useState, useEffect } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "@mui/material/Button"

export const apiListToGantFormat = (
  apiList,
  getName = (w) => w.workCategory.name || "No Name",
  isDisabled = false
) => {
  return apiList.map((pw) => ({
    start: new Date(pw.startDate + "T00:00:00"),
    end: new Date(pw.endDate + "T00:00:00"),
    name: getName(pw),
    id: pw.id,
    progress: 0,
    type: "task",
    displayOrder: pw.sort,
    quantity: pw.quantity,
    demandItem: pw.demandItem,
    isDisabled,
  }))
}

const gantFormatToApiList = (gantList) => {
  return gantList.map((pw) => ({
    startDate: pw.start,
    endDate: pw.end,
    workCategoryId: pw.category,
    demandItemId: pw.demandItemId,
    quantity: pw.quantity,
    sort: pw.displayOrder,
    id: pw.id,
  }))
}

const WorkDetail = ({ demand, close }) => {
  const [plannedWorks, setPlannedWorks] = useState(null)
  const [newPlannedWork, setNewPlannedWork] = useState(null)
  const [allWorkCategories, setAllWorkCategories] = useState(null)

  const {
    execute: executeGetPlannedWorks,
    isLoading: listLoading,
    data: plannedWorksFromAPI,
  } = useFetchWithMsal()

  const { execute: executeUpdatePlannedWorks, isLoading: writeLoading } =
    useFetchWithMsal()

  useEffect(() => {
    if (demand) {
      executeGetPlannedWorks("GET", `/api/demand/plannedworks/${demand.id}`)
    }
  }, [demand, executeGetPlannedWorks])

  const handleBulkUpdatePlannedWorks = async (newPlannedWorks) => {
    const apiList = gantFormatToApiList(newPlannedWorks)
    const resp = await executeUpdatePlannedWorks(
      "PUT",
      "/api/demand/plannedworks/" + demand.id,
      apiList
    )
    if (resp) {
      // const gantformatted = resp ? apiListToGantFormat(resp) : []
      // setPlannedWorks(gantformatted)
      // console.log(resp)
    }
  }

  const sendUpdateQuantity = async (id, quantity) => {
    const resp = await executeUpdatePlannedWorks(
      "POST",
      "/api/work/plannedworks",
      {
        id,
        quantity,
        demandItemId: demand.id,
      }
    )

    if (resp) {
      // const gantformatted = resp ? apiListToGantFormat(resp) : []
      // setPlannedWorks(gantformatted)
      // console.log(resp)
    }
  }

  const handleCreateNewPlannedWork = async (newPlannedWork) => {
    const resp = await executeUpdatePlannedWorks(
      "POST",
      "/api/work/plannedworks",
      newPlannedWork
    )
    if (resp) {
      const gantformatted = resp ? apiListToGantFormat(resp) : []
      setPlannedWorks(gantformatted)
    }
  }

  const handleDeletePlannedWorks = async (ids) => {
    const resp = await executeUpdatePlannedWorks(
      "DELETE",
      "/api/work/plannedworks",
      { ids }
    )
    if (!resp) {
      console.log("error deleting")
      return
    }
    const newState = plannedWorks.filter((pw) => !ids.includes(pw.id))
    setPlannedWorks(newState)
  }

  const handleTaskChange = (allTasks, task) => {
    const tasks = [...allTasks]
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t))

    for (let i = 0; i < newTasks.length; i++) {
      newTasks[i].displayOrder = i
    }

    setPlannedWorks(newTasks)
    handleBulkUpdatePlannedWorks(newTasks)
  }

  const handleUpdateQuantity = (alltasks, task, quantity) => {
    sendUpdateQuantity(task.id, quantity)

    // optimistic update
    const newTasks = alltasks.map((t) =>
      t.id === task.id ? { ...t, quantity } : t
    )
    setPlannedWorks(newTasks)
  }

  const handleTaskReorder = (allTasks, task, direction) => {
    const tasks = [...allTasks]
    const index = tasks.findIndex((t) => t.id === task.id)

    if (
      (direction === "up" && index === 0) ||
      (direction === "down" && index === tasks.length - 1)
    ) {
      console.log("Cannot move")

      return
    }

    const newTasks = [...tasks]
    const temp = newTasks[index]
    newTasks[index] = newTasks[index + (direction === "up" ? -1 : 1)]
    newTasks[index + (direction === "up" ? -1 : 1)] = temp

    for (let i = 0; i < newTasks.length; i++) {
      newTasks[i].displayOrder = i
    }

    setPlannedWorks(newTasks)
    handleBulkUpdatePlannedWorks(newTasks)
  }

  useEffect(() => {
    if (plannedWorksFromAPI) {
      const works = plannedWorksFromAPI["plannedWorks"]
      const gantformatted = works ? apiListToGantFormat(works) : []
      setPlannedWorks(gantformatted)
      setAllWorkCategories(plannedWorksFromAPI["allWorkCategories"])
    }
  }, [plannedWorksFromAPI])

  return (
    <div className="DemandDetail WorkDetail">
      {(writeLoading || listLoading) && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="topCont">
        <div>
          <div>
            {demand.type === "B2B" ? (
              <>
                {demand.externalSalesContract
                  ? demand.externalSalesContract.blend
                  : demand.contractBlend}{" "}
                -{" "}
                {demand.externalSalesContract
                  ? demand.externalSalesContract.customerName
                  : demand.customer}{" "}
                -{" "}
                {demand.externalSalesContract
                  ? demand.externalSalesContract.description
                  : demand.description}
              </>
            ) : (
              <>{demand.allocationBlend} </>
            )}
          </div>
        </div>
        <div className="actionsCont">
          <div>
            {allWorkCategories && (
              <select
                value={newPlannedWork?.category || ""}
                onChange={(e) =>
                  setNewPlannedWork({
                    ...newPlannedWork,
                    category: e.target.value,
                  })
                }
              >
                <option value="" disabled>
                  Add New Line...
                </option>
                {allWorkCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <input
              type="number"
              value={newPlannedWork?.quantity || ""}
              onChange={(e) =>
                setNewPlannedWork({
                  ...newPlannedWork,
                  quantity: e.target.value,
                })
              }
              style={{ width: "100px" }}
              placeholder="Gallons"
            />
          </div>
          {newPlannedWork &&
            newPlannedWork.category &&
            newPlannedWork.quantity && (
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  handleCreateNewPlannedWork({
                    startDate: new Date(),
                    endDate: new Date(
                      new Date().setMonth(new Date().getMonth() + 4)
                    ),
                    quantity: newPlannedWork.quantity,
                    workCategoryId: newPlannedWork.category,
                    demandItemId: demand.id,
                    sort: -1,
                  })
                  setNewPlannedWork(null)
                }}
              >
                Add Work
              </Button>
            )}
          <div>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={close}
            >
              Close Detail
            </Button>
          </div>
        </div>
      </div>
      <div className="gantcont">
        {plannedWorks && plannedWorks.length > 0 && (
          <Gantt1
            tasks={plannedWorks || []}
            handleTaskDelete={(id) => handleDeletePlannedWorks([id])}
            handleTaskChange={handleTaskChange}
            handleTaskReorder={handleTaskReorder}
            handleUpdateQuantity={handleUpdateQuantity}
          />
        )}
      </div>
    </div>
  )
}

export default WorkDetail
