import { useEffect, useState } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { TextField, FormControlLabel, Checkbox } from "@mui/material"
import Button from "@mui/material/Button"

const data_URL = "/api/sales/compliance"
const types_URL = "/api/sales/md?resources=salesOrderTypes"

const validateComplianceInfo = (info) => {
  if (!info.salesOrderTypeId) {
    return "Order Type is required"
  }
  if (!info.state) {
    return "Recipient State is required"
  }
}

export const ComplianceEditor = ({ _focusedRow, onUpdate }) => {
  const [info, setInfo] = useState(_focusedRow)

  const { execute: executeGetComplianceInfo, data = [] } = useFetchWithMsal()
  const { execute: executeGetSalesOrdersTypes, data: types = [] } =
    useFetchWithMsal()
  const { execute: executeUpdateComplianceInfo } = useFetchWithMsal()

  useEffect(() => {
    if (!data) {
      executeGetComplianceInfo("GET", data_URL)
    }
  }, [executeGetComplianceInfo, data])

  useEffect(() => {
    if (!types) {
      executeGetSalesOrdersTypes("GET", types_URL)
    }
  }, [executeGetSalesOrdersTypes, types])

  useEffect(() => {
    if (info.salesOrderTypeId && info.state && data) {
      const matchingRow = data.find(
        (row) =>
          row.salesOrderTypeId === info.salesOrderTypeId &&
          row.state === info.state
      )

      if (matchingRow) {
        setInfo((prevInfo) => ({
          ...prevInfo,
          id: matchingRow.id || "",
          allowed: matchingRow.allowed || "",
          notes: matchingRow.notes || "",
          isValid: matchingRow?.isValid || false,
          requiresTaxExempt: matchingRow?.requiresTaxExempt || false,
          retailShipping: matchingRow?.retailShipping || false,
          dtcShipping: matchingRow?.dtcShipping || false,
        }))
      }
    }
  }, [info.salesOrderTypeId, info.state, data])

  const updateComplianceInfo = async () => {
    try {
      await executeUpdateComplianceInfo("POST", `${data_URL}/${info.id}`, info)
    } catch (err) {
      alert(err)
    }
  }

  return (
    <div
      className="ComplianceEditor"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20px",
      }}
    >
      <div
        className="formRow"
        style={{
          display: "flex",
          marginBottom: "15px",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <TextField
          id="state"
          value={info.state}
          label="State"
          required
          fullWidth
          inputProps={{ readOnly: true }}
          style={{ marginRight: "10px" }}
        />
        <TextField
          id="orderType"
          value={info.salesOrderTypeId}
          label="Order Type"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </div>
      <div className="formRow">
        <FormControlLabel
          required
          checked={info.isValid}
          control={
            <Checkbox
              onChange={(e) => setInfo({ ...info, isValid: !info.isValid })}
            />
          }
          label="Enabled"
        />
        {info.salesOrderTypeId === "Donation" ||
        info.salesOrderTypeId === "Event" ? (
          <FormControlLabel
            required
            checked={info.requiresTaxExempt}
            control={
              <Checkbox
                onChange={(e) =>
                  setInfo({
                    ...info,
                    requiresTaxExempt: !info.requiresTaxExempt,
                  })
                }
              />
            }
            label="Require 501c3"
          />
        ) : null}
        {info.salesOrderTypeId === "Donation" ||
        info.salesOrderTypeId === "Event" ||
        info.salesOrderTypeId === "Retailer Tasting/Presentation" ? (
          <FormControlLabel
            required
            checked={info.retailShipping}
            control={
              <Checkbox
                onChange={(e) =>
                  setInfo({
                    ...info,
                    retailShipping: !info.retailShipping,
                  })
                }
              />
            }
            label="Allow Retailer Shipping"
          />
        ) : null}
        {info.salesOrderTypeId !== "Distributor/Control State Agency" ? (
          <FormControlLabel
            required
            checked={info.dtcShipping}
            control={
              <Checkbox
                onChange={(e) => {
                  setInfo({
                    ...info,
                    dtcShipping: !info.dtcShipping,
                  })
                }}
              />
            }
            label="Allow DTC Shipping"
          />
        ) : null}
      </div>
      <div
        className="formRow"
        style={{ marginBottom: "15px", width: "100%", maxWidth: "500px" }}
      >
        <TextField
          id="allowed"
          value={info.allowed}
          label="Allowed"
          fullWidth
          required
          multiline
          onChange={(e) => setInfo({ ...info, allowed: e.target.value })}
        />
      </div>
      <div
        className="formRow"
        style={{ marginBottom: "15px", width: "100%", maxWidth: "500px" }}
      >
        <TextField
          id="notes"
          value={info.notes}
          label="Notes"
          fullWidth
          required
          multiline
          onChange={(e) => setInfo({ ...info, notes: e.target.value })}
        />
      </div>
      <div
        className="formRow"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            const error = validateComplianceInfo(info)
            if (error) {
              alert(error)
              return
            }
            await updateComplianceInfo()
            onUpdate()
          }}
          style={{ marginRight: "10px" }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onUpdate()
          }}
        >
          Close
        </Button>
      </div>
    </div>
  )
}

export default ComplianceEditor
