import React, { useState, useEffect } from "react"
import { Button } from "@mui/material"

const referenceDate = new Date(2022, 7, 1) // Month is 0-based, so 7 is August

const IdealDatesEditor = ({ demandItem, currentDates, close, save }) => {
  const [dates, setDates] = useState({
    idealStartShipping: null,
    idealStopShipping: null,
    mustStopShipping: null,
  })

  useEffect(() => {
    if (currentDates) {
      // Convert days from Aug 1st back to actual dates
      const idealStart = new Date(
        referenceDate.getTime() +
          currentDates.idealStartShipping * 24 * 60 * 60 * 1000
      )
      const idealStop = new Date(
        referenceDate.getTime() +
          currentDates.idealStopShipping * 24 * 60 * 60 * 1000
      )
      const mustStop = new Date(
        referenceDate.getTime() +
          currentDates.mustStopShipping * 24 * 60 * 60 * 1000
      )

      setDates({
        idealStartShipping: idealStart.toISOString().split("T")[0],
        idealStopShipping: idealStop.toISOString().split("T")[0],
        mustStopShipping: mustStop.toISOString().split("T")[0],
      })
    }
  }, [currentDates])

  const handleSave = () => {
    if (
      !dates.idealStartShipping ||
      !dates.idealStopShipping ||
      !dates.mustStopShipping
    )
      return

    const idealStart = new Date(dates.idealStartShipping)
    const idealStop = new Date(dates.idealStopShipping)
    const mustStop = new Date(dates.mustStopShipping)

    // Calculate days from Aug 1st
    save({
      idealStartShipping: Math.round(
        (idealStart - referenceDate) / (24 * 60 * 60 * 1000)
      ),
      idealStopShipping: Math.round(
        (idealStop - referenceDate) / (24 * 60 * 60 * 1000)
      ),
      mustStopShipping: Math.round(
        (mustStop - referenceDate) / (24 * 60 * 60 * 1000)
      ),
      demandItemId: demandItem.id,
    })
  }

  return (
    <div className="IdealDatesEditor">
      <h3>Edit Ideal Dates</h3>
      <div>{demandItem?.allocationBlend}</div>
      <div>Dates in reference to Aug 1 2022 Crush</div>

      <div style={{ marginTop: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <label style={{ marginRight: "10px" }}>Ideal Start Shipping:</label>
          <input
            type="date"
            value={dates.idealStartShipping || "2022-08-01"}
            onChange={(e) =>
              setDates((prev) => ({
                ...prev,
                idealStartShipping: e.target.value,
              }))
            }
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label style={{ marginRight: "10px" }}>Ideal Stop Shipping:</label>
          <input
            type="date"
            value={dates.idealStopShipping || "2022-08-01"}
            onChange={(e) =>
              setDates((prev) => ({
                ...prev,
                idealStopShipping: e.target.value,
              }))
            }
          />
        </div>

        <div style={{ marginBottom: "20px" }}></div>
        <label style={{ marginRight: "10px" }}>Must Stop Shipping:</label>
        <input
          type="date"
          value={dates.mustStopShipping || "2022-08-01"}
          onChange={(e) =>
            setDates((prev) => ({ ...prev, mustStopShipping: e.target.value }))
          }
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            !dates.idealStartShipping ||
            !dates.idealStopShipping ||
            !dates.mustStopShipping
          }
          style={{ marginRight: "10px" }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default IdealDatesEditor
