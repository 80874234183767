import React from "react"
import { Card, CardContent, Box } from "@mui/material"

const QuickLink = ({ url, label, caption }) => {
  return (
    <Box
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        textDecoration: "none",
      }}
    >
      <Card
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          color: "#fff",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "scale(1.03)",
            boxShadow: 6,
          },
          cursor: "pointer",
          minWidth: 100,
          maxWidth: 200,
          height: "100%",
          maxHeight: 90,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 1,
        }}
      >
        <CardContent
          sx={{ padding: "8px", "&:last-child": { paddingBottom: "8px" } }}
        >
          <h6 style={{ fontWeight: "bold", margin: 0 }}>{label}</h6>
          <p
            style={{
              fontSize: "0.8rem",
              color: "#fff",
              margin: 0,
            }}
          >
            {caption}
          </p>
        </CardContent>
      </Card>
    </Box>
  )
}

export default QuickLink
