import { useEffect, useState } from "react"
// import { PermissionsContext } from "../App"
import Page from "../components/Page"
// import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Stack from "@mui/material/Stack"
import "../styles/SalesOrders.css"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid"
import { TextField, Button } from "@mui/material"

const data_URL = "/api/sales/orders?admin=true"

export const SalesOrdersAdminPage = () => {
  const [focusedRow, setFocusedRow] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [trackingNumber, setTrackingNumber] = useState("")
  const focusedAdditionalInfo = focusedRow
    ? JSON.parse(focusedRow.additionalInfo)
    : null

  const { execute: executeGetOrders, data, isLoading } = useFetchWithMsal()
  const { execute: executePost, isLoading: posting } = useFetchWithMsal()

  useEffect(() => {
    if (!data && !isLoading) {
      executeGetOrders("GET", data_URL)
    }
  }, [executeGetOrders, data, isLoading])

  const updateSalesOrder = async (id, data) => {
    try {
      const resp = await executePost("POST", `/api/sales/update/${id}`, data)

      if (resp.error) {
        console.log(resp)

        alert("Error updating order")
        return
      }

      setFocusedRow(resp)
    } catch (e) {
      alert("Error updating order")
      return
    }

    executeGetOrders("GET", data_URL)
  }

  return (
    <Page fullHeight className="SalesOrdersPage">
      {(isLoading || posting) && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div>
            All Orders - Click on a row to see the details and change status
          </div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <div></div>
        </Stack>
      </div>
      <div className="body">
        <div
          style={{
            width: "100%",
            height: "100%",
            flex: 1,
            maxHeight: "100%",
          }}
          className="gridContainer"
        >
          <DataGrid
            rows={data || []}
            columns={[
              {
                field: "id",
                headerName: "ID",
                width: 60,
              },
              {
                field: "dateOfRequest",
                headerName: "Submitted",
                width: 100,
              },
              {
                field: "requiredDeliveryDate",
                headerName: "Delivery",
                width: 100,
              },
              {
                field: "status",
                headerName: "Status",
                width: 140,
              },
              {
                field: "user",
                headerName: "Requestor",
                width: 140,
                valueGetter: (params) => {
                  return params.row?.user?.name
                },
              },
              {
                field: "rName",
                headerName: "Recipient",
                flex: 1,
                maxWidth: 400,
                width: 150,
              },
              {
                field: "products",
                headerName: "Products",
                flex: 1,
                minWidth: 300,
                valueGetter: (params) => {
                  return params.row?.salesOrderItems
                    ?.map((item) => item.productId)
                    .join(", ")
                },
              },
              {
                field: "rState",
                headerName: "State",
                width: 100,
              },
              {
                field: "lastModified",
                headerName: "Last Modified By",
                width: 150,
                valueGetter: (params) => {
                  // Extract change log from row
                  const changes = params.row?.changeLogs || []

                  // Get the last modified entry (latest by date)
                  const lastChange = changes.length
                    ? changes.reduce((latest, current) =>
                        new Date(latest.changedAt) > new Date(current.changedAt)
                          ? latest
                          : current
                      )
                    : null

                  return lastChange?.user || "N/A" // Display last modified user or "N/A"
                },
              },
            ]}
            sortModel={[
              {
                field: "id",
                sort: "desc",
              },
            ]}
            hideFooterSelectedRowCount={true}
            disableDensitySelector
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            slots={{ toolbar: GridToolbar }}
            pagination
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 40}
            columnHeaderHeight={40}
            pageSizeOptions={[100]}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
            onRowClick={(row) => {
              setFocusedRow(row?.row)
              setTrackingNumber(row?.row.trackingNumber)
            }}
          />
        </div>
      </div>
      {!!focusedRow && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={!!focusedRow}
          onClose={() => setFocusedRow(null)}
        >
          <Box style={{ padding: "10px" }}>
            <div className="DetailsCont SalesOrders">
              <div
                className="head"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1 }}>
                  <div>Order #{focusedRow.id}</div>
                  <div>Requestor: {focusedRow.user?.name}</div>
                  <div>Date Requested: {focusedRow.dateOfRequest}</div>
                  <div>
                    Requested Delivery Date: {focusedRow.requiredDeliveryDate}
                  </div>
                </div>
                <div style={{ flex: 1, alignItems: "flex-start" }}>
                  <div>
                    Status:
                    <select
                      value={focusedRow.status}
                      onChange={(e) =>
                        updateSalesOrder(focusedRow.id, {
                          status: e.target.value,
                        })
                      }
                    >
                      <option value="Pending Review">Pending Review</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Shipped">Shipped</option>
                      <option value="Inactive">
                        Inactive (will be hidden)
                      </option>
                    </select>
                  </div>
                  <div style={{ marginTop: 2 }}>
                    Recipient Email: {focusedRow?.rEmail}
                  </div>
                  <Stack
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    marginTop={1}
                  >
                    <TextField
                      value={trackingNumber || ""}
                      label="Tracking Number"
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      fullWidth
                      required
                      size="small"
                      disabled={!isEditing}
                    />
                    {isEditing ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          if (focusedRow?.trackingNumber !== trackingNumber) {
                            updateSalesOrder(focusedRow.id, { trackingNumber })
                          }
                          setIsEditing(false)
                        }}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Stack>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="body" style={{ flex: 1 }}>
                  <div>
                    <div className="headr">Type</div>
                    <div>{focusedRow.salesOrderType?.description}</div>
                  </div>
                  <div>
                    <div className="headr">Recipient</div>
                    {focusedRow.rName}
                  </div>
                  <div>
                    <div className="headr">Address</div>
                    <div>{focusedRow.rStreet}</div>
                    <div>
                      {focusedRow.rCity}, {focusedRow.rState} {focusedRow.rZip}
                    </div>
                    <div>{focusedRow.rCountry}</div>
                  </div>
                  <div>
                    <div className="headr">Notes</div>
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {focusedRow.notes
                        ? focusedRow.notes
                        : "No notes provided"}
                    </div>
                  </div>
                  <div>
                    <div className="headr">Products</div>
                    <div>
                      {focusedRow.salesOrderItems?.map((item) => (
                        <div key={item.id}>
                          {item.product?.id} - {item.cases} cases,{" "}
                          {item.bottles} bottles
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="body" style={{ flex: 1 }}>
                  <div className="headr">Compliance Info</div>
                  <div>
                    Event or Retail: {focusedAdditionalInfo?.eventOrRetail}
                  </div>
                  <div>
                    Event Non Profit: {focusedAdditionalInfo?.eventNonProfit}
                  </div>
                  <div>
                    Event Non Profit Name:{" "}
                    {focusedAdditionalInfo?.eventNonProfitName}
                  </div>
                  <div>
                    501c3 file upload:{" "}
                    <a href={focusedRow?.taxStatusUrl}>
                      {focusedRow?.taxStatusUrl}
                    </a>
                  </div>
                  <div>
                    <div className="headr">Status Change Log</div>
                    <DataGrid
                      rows={focusedRow.changeLogs || []}
                      columns={[
                        {
                          field: "user",
                          headerName: "Modified By",
                          width: 150,
                        },
                        {
                          field: "changedAt",
                          headerName: "Date Changed",
                          width: 110,
                        },
                        {
                          field: "oldStatus",
                          headerName: "Old Status",
                          width: 130,
                        },
                        {
                          field: "newStatus",
                          headerName: "Status",
                          width: 130,
                        },
                      ]}
                      sortModel={[
                        {
                          field: "changedAt",
                          sort: "asc",
                        },
                      ]}
                      sx={{
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                      disableRowSelectionOnClick
                      disableColumnMenu
                      disableDensitySelector
                      hideFooter
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Dialog>
      )}
    </Page>
  )
}
