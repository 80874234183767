import { AuthenticatedTemplate } from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"
import Page from "../components/Page"
import { useState, useEffect } from "react"
import TintedBackground from "../components/TintedBackground"
import bpImage from "../assets/images/Home.jpg"
import QuickLink from "../components/QuickLink"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { Box } from "@mui/material"

const data_URL = "/api/homepage"

export const Home = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [categories, setCategories] = useState([])

  const { execute: executeGetHomepage, data, isLoading } = useFetchWithMsal()

  useEffect(() => {
    if (!data) {
      executeGetHomepage("GET", data_URL)
    }
    // set unique categories from the data
    setCategories(
      data?.reduce((acc, link) => {
        if (!acc.includes(link.category)) {
          acc.push(link.category)
        }
        return acc
      }, [])
    )
  }, [executeGetHomepage, data, isLoading])

  return (
    <Page className="homepage">
      <AuthenticatedTemplate>
        <TintedBackground
          imageUrl={bpImage}
          tintColor="black"
          tintOpacity={0.7}
        >
          {activeAccount ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                width: "100vw",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  minHeight: 0,
                  overflowY: "auto",
                  padding: 2,

                  // Transparent scrollbar styles
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {categories?.map((category) => (
                  <Box key={category} sx={{ marginBottom: 1 }}>
                    <Box sx={{ fontWeight: "bold" }}>{category}</Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                    >
                      {data
                        .filter((link) => link.category === category)
                        .map((link) => (
                          <QuickLink
                            key={link.id}
                            label={link.label}
                            url={link.url}
                            caption={link.caption}
                          />
                        ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : null}
        </TintedBackground>
      </AuthenticatedTemplate>
    </Page>
  )
}
